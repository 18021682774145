@mixin smartphone_and_tablet_portrait_common_properties {
    @media (min-height: 781px) and (max-height: 869px) {
        grid-template-rows: 3rem 27rem 8rem;
    }

    @media (min-height: 870px) and (max-height: 900px) {
        grid-template-rows: 4rem 27rem 8rem;
    }

    @media (min-height: 900px) and (max-height: 929px) {
        grid-template-rows: 5rem 27rem 10rem;
    }
}

@mixin between_smartphone_and_tablet_landscape {
    @media (min-height: 481px) and (max-height: 520px) {
        grid-template-rows: 1.5rem 21rem 5rem;
    }

    @media (min-height: 521px) and (max-height: 590px) {
        grid-template-rows: 1.5rem 23.5rem 5rem;
    }

    @media (min-height: 591px) and (max-height: 615px) {
        grid-template-rows: 1.5rem 25.5rem 5rem;
    }
    
    @media (min-height: 616px) and (max-height: 787px) {
        grid-template-rows: 1.5rem 26rem 5rem;
    }

    @media (min-height: 788px) and (max-height: 807px) {
        grid-template-rows: 2rem 26rem 5rem;
    }

    @media (min-height: 808px) {
        grid-template-rows: 2rem 27rem 5rem;
    }
}

@mixin _grid_wrapper_main_title_area_media_properties {
    @media only screen {

        // Smartphone (portrait and landscape) and some Tablet
        @media (min-width: 320px) and (max-width: 929px) {

            // Portrait only
            @media (orientation: portrait) {
                @include smartphone_and_tablet_portrait_common_properties();
            }

            // Landscapte only
            @media (orientation: landscape) {
                @media (max-height: 359px) {
                    grid-template-rows: 0.25rem 12.5rem 1rem;
                }

                @media (min-height: 359px) and (max-height: 378px) {
                    grid-template-rows: 0.25rem 12.5rem 1rem;
                }

                @media (min-height: 379px) and (max-height: 398px) {
                    grid-template-rows: 0.25rem 15rem 2rem;
                }

                @media (min-height: 399px) and (max-height: 418px) {
                    grid-template-rows: 0.25rem 16.25rem 3rem;
                }

                @media (min-height: 419px) and (max-height: 438px) {
                    grid-template-rows: 0.25rem 17.5rem 3rem;
                }

                @media (min-height: 439px) and (max-height: 450px) {
                    grid-template-rows: 0.25rem 18.5rem 3rem;
                }

                @media (min-height: 451px) and (max-height: 480px) {
                    grid-template-rows: 0.25rem 20rem 3rem;
                }
            }
        }

        // Tablets, Laptops and Desktops (Portrait and Landscape)
        @media (min-width: 768px) and (max-width: 1280px) {
            // Portrait only
            @media (orientation: portrait) {
                @include smartphone_and_tablet_portrait_common_properties();
    
                @media (min-height: 930px) and (max-height: 965px) {
                    grid-template-rows: 6rem 27rem 12rem;
                }
    
                @media (min-height: 966px) and (max-height: 995px) {
                    grid-template-rows: 7rem 27rem 12rem;
                }
    
                @media (min-height: 995px) and (max-height: 1025px) {
                    grid-template-rows: 8rem 27rem 12rem;
                }
    
                @media (min-height: 1026px) and (max-height: 1056px) {
                    grid-template-rows: 9rem 27rem 12rem;
                }
    
                @media (min-height: 1056px) and (max-height: 1100px) {
                    grid-template-rows: 10rem 27rem 12rem;
                }
    
                @media (min-height: 1100px) and (max-height: 1150px) {
                    grid-template-rows: 11.5rem 27rem 12rem;
                }
    
                @media (min-height: 1150px) and (max-height: 1200px) {
                    grid-template-rows: 13rem 27rem 17rem;
                }
    
                @media (min-height: 1200px) and (max-height: 1240px) {
                    grid-template-rows: 14.5rem 27rem 19rem;
                }
    
                @media (min-height: 1240px) {
                    grid-template-rows: 16rem 27rem 20rem;
                }
            }

            // Landscape only
            @media (orientation: landscape) {
                @include between_smartphone_and_tablet_landscape();
            }

        }

        // Gap between smartphone and tablet not covered above
        @media (min-width: 481px) and (max-width: 767px) and (orientation: landscape) {
            @include between_smartphone_and_tablet_landscape();
        }
    }
}