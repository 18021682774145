#contact-details {
    background-color: $medium-light-blue;
    @include height-width(auto, 25%);
    padding: 0.625rem 2.1875rem;
    justify-self: center;
}

.presentation-legal-info-title {
    font-weight: bold;
}

.presentation-legal-info-title-bullet {
    &::before {
        @include bullet-triangle-common-properties;
        left: 0;
        top: 0.45em;
    }
}

#presentation-job-domain-list {
    & ul {
        list-style: none;

        & li {
            &::before {
                @include bullet-triangle-common-properties;
                left: -1em;
                top: 1.15em;
            }
        }
    }
}

@media only screen {
    // Smartphone (portrait and landscape) and some Tablets (from 768px)
    @media (min-width: 320px) and (max-width: 929px) {
        // Portrait only
        @media (orientation: portrait) {
            #contact-details {
                width: 70%;
            }
        }
        // Landscape only
        @media (orientation: landscape) {
            #contact-details {
                width: 35%;
            }
        }
    }

    // Some smartphone (until 926px), and Tablets (Portrait and Landscape)
    @media (min-width: 768px) and (max-width: 1280px) {
        // Not smartphone
        @media (min-height: 481px) {
            // Portrait only
            @media (orientation: portrait) {
                #contact-details {
                    width: 35%;
                }
            }
            // Landscape only
            @media (orientation: landscape) {
                #contact-details {
                    width: 28%;
                }
            }
        }
    }
}