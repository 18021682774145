#interested-by-us {
    background-color: $really-light-green;
    border-radius: 0.25rem;
}

.contact-way-details {
    border: 0.0625rem solid $black;
    padding: 0.3125rem;
    border-radius: 0.25rem;
}

#mail-address-contact-way {
    width: 42%;
}

#portable-contact-way {
    width: 30%;
}

#fb-contact-way {
    width: 67%;

    & a {
        text-decoration: none;

        &:hover {
            color: $navy-blue;
        }
    }
}

@media only screen {

    // Smartphone (portrait and landscape) and some Tablets (from 768px)
    @media (min-width: 320px) and (max-width: 929px) {
        #mail-address-contact-way {
            width: 65%;
        }

        #portable-contact-way {
            width: 45%;
        }

        // Portrait only
        @media (orientation: portrait) {
            #fb-contact-way {
                width: 97%;
            }
        }

        // Landscape only
        @media (orientation: landscape) {
            #fb-contact-way {
                width: 98%;
            }
        }
    }

    // Some smartphone (until 926px), and Tablets (Portrait and Landscape)
    @media (min-width: 767px) and (max-width: 1280px) {
        @media (orientation: portrait) {
            #fb-contact-way {
                width: 99%;
            }
        }
        @media (orientation: landscape) {
            #fb-contact-way {
                width: 82%;
            }
        }
    }
    
    // Configured navigator windows size
    @media (orientation: landscape) {
        @media (min-width: 929px) and (max-width: 1075px) {
            #mail-address-contact-way {
                width: 60%;
            }

            #portable-contact-way {
                width: 45%;
            }
        }
    }
}