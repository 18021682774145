button {
    background-color: $slightly-dark-blue;
    @include font-weight-color(bold, $white);
    font-size: 0.875rem;
    padding: 0.625rem 1.875rem;
    border: none;
    border-radius: 1.25rem;
    cursor: pointer;

    &:hover {
        background-color: $light-blue;
    }
}

#button-form-sending-message {
    width: 30%;
}

@media only screen {
    // Smartphone (portrait and landscape) and some Tablets (from 768px)
    @media (min-width: 320px) and (max-width: 929px) {
        #button-form-sending-message {
            width: 40%;
        }
    }
    // Some smartphone (until 926px), and Tablets (Portrait and Landscape)
    @media (min-width: 768px) and (max-width: 1280px) {
        @media (orientation: portrait) {
            #button-form-sending-message {
                width: 45%;
            }
        }
        @media (orientation: landscape) {
            #button-form-sending-message {
                width: 40%;
            }
        }
    }

    // From 1426px, the nav bar is well displayed -> no need to display the icon
    @media (max-width: 1425px) {
        #icon-navigation {
            & button {
                border: none;
                background-color: $white;
                color: $navy-blue;
                font-size: 1.7rem;
                font-weight: bold;
                cursor: pointer;
            }
        }

        .navigation {
            &.menu-displayed {
                & #primary-navigation {
                    & #button-cancel {
                        display: block;
                        padding: 0.5rem 0.65rem;
                        background-color: $extremely-light-blue;
                        border: 0.2rem solid $navy-blue;
                        border-radius: 2rem;
                        font-size: 1rem;
                        cursor: pointer;
                        justify-self: right;
                        margin: 1rem 0.8rem 0 0;

                        & img {
                            width: 1rem;
                        }
                    }
                }
            }
        }
    }
}