.contact-way-icon {
    justify-self: center;
    padding: 0.5rem 0 0.5rem 0.5rem;

    & img {
        @include height-width(1rem, 1rem);
    }
}

#postal-address-contact-way-icon {
    & img {
        @include height-width(1.15rem, 0.90rem);
    }
}

.fb-contact-way-icon {
    @include height-width(1.5rem, 1.5rem);

    & img {
        @include height-width(100%, 100%);
    }
    
    #icon-2-facebook {
        display: none;
    }

    &:hover {
        #icon-1-facebook {
            display: none;
        }
        #icon-2-facebook {
            display: block;
            @include height-width(1.58rem, 1.5rem);
        }
    }
}

#cv-manager img {
    @include height-width(1.375rem, 1.11rem);
    margin: 0 0 0 0.125rem;
}

#sent-message-status img {
    @include height-width(2.1875rem, 2.1875rem);
}