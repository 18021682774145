.down-arrow {
    @include height-width(100%, 0.3125rem);
    float: left;
}

.vertical-line {
    border-left: 0.125rem solid $navy-blue;
    height: 2.6875rem;
    @include margin-padding(0, 0);
}

.down-arrow-head {
    @include height-width(0, 0);
    border-style: solid;
    border-width: 0.4375rem;
    border-color: $navy-blue transparent transparent transparent;
    @include margin-padding(0 0 0 -0.375rem, 0);
}