#logo-betis-header {
    & img {
        @include height-width(6.5625rem, 9.31875rem);
    }
}

.study-image-example {
    justify-self: center;

    & img {
        width: 100%;
    }
}

.study-big-image-example {
    width: 73%;
}

#building-permit-request-image {
    width: 52%;
}

#building-plan-2D-image {
    width: 65%;
}

#building-plan-3D-image {
    width: 85%;
}

@media only screen {
    // Smartphone (portrait and landscape) and some Tablets (from 768px)
    @media (min-width: 320px) and (max-width: 929px) {
        // Landscape and portrait
        .study-image-example {
            width: 95% !important;
        }

        // Portrait only
        @media (orientation: portrait) {
            #building-permit-request-image, #building-plan-2D-image, #building-plan-3D-image {
                width: 100% !important;
            }
        }
    }
}