.width-50percent {
  width: 50%;
}

.light-blue {
  color: rgba(31, 142, 209, 0.96);
}

.color-navy-blue {
  color: rgba(5, 2, 96, 0.96);
}

.color-red {
  color: rgb(255, 0, 13);
}

.color-blue {
  color: rgb(0, 0, 255);
}

.color-white {
  color: #fff;
}

.background-color-very-light-grey {
  background-color: rgba(224, 224, 224, 0.161);
}

.font-weight-bold {
  font-weight: bold;
}

.font-size-1-125rem {
  font-size: 1.125rem;
}

.fton-size-1-1875rem {
  font-size: 1.1875rem;
}

.font-size-3-125rem {
  font-size: 3.125rem;
}

.text-decoration-underline {
  text-decoration: underline;
}

.text-decoration-none {
  text-decoration: none;
}

.float-left {
  float: left;
}

.float-right {
  float: right;
}

.width-14percent {
  width: 14%;
}

.width-75percent {
  width: 75%;
}

.width-70percent {
  width: 70%;
}

.width-35percent {
  width: 35%;
}

.width-50percent {
  width: 50%;
}

.width-40percent {
  width: 40%;
}

.margin-0-0-0-4-375rem {
  margin: 0 0 0 4.375rem;
}

.justify-self-right {
  justify-self: right;
}

.justify-self-left {
  justify-self: left;
}

.top-0 {
  top: 0;
}

.top-4rem {
  top: 4rem;
}

.top-minus-3 {
  top: -3rem;
}

