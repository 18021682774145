body {
    font-family: -apple-system, BlinkMacSystemFont, 'Calibri', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 1rem;

    text-align: justify;
}
  
code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}

#primary-menu {
    & li {
        & a {
            @include font-size-color(0.875rem, $light-blue);
            font-weight: bold;
            text-transform: uppercase;
            text-decoration: none;

            &.activated, &:hover {
                color: $red;
            }
        }
    }
}

#organizational-chart-content {
    text-align: center;
}

#main-title {
    @include font-size-color(5rem, $navy-blue);
    font-weight: bold;
    justify-self: center;
    text-align: center;
    margin: auto 0;
}

.main-title-complement {
    @include font-size-color(2.5rem, $navy-blue);
    text-align: center;
    line-height: 1.2;
}

.title-1-with-bullet {
    font-weight: bold;
    @include font-size-color(1.25rem, $light-blue);
}

.title-2 {
    @include font-weight-color(bold, $light-blue);
}

.interested-by-us-main-title {
    @include font-size-color(5rem, $slightly-dark-blue);
    font-weight: bold;
}

.contact-way-details {
    @include font-weight-color(bold, $light-blue);
}

#sent-message-status {
    font-weight: bold;
    font-size: 1.125rem;
}

.input-field-error-message {
    @include font-size-color(0.875rem, $red);
    float: right;
}

#contact-details {
    @include font-size-color(1.0625rem, $navy-blue);
    font-weight: bold;
    text-align: center;

    & a {
        @include font-size-color(1.125rem, $dark-navy-blue);

        &:hover {
            color: $white;
        }
    }
}

#organizational-chart-manager {
    color: $white;
}

#organizational-chart-complement {
    color: $light-blue;   
}

#cv-manager {
    & a {
        text-decoration: none;
        color: $black;

        &:hover {
            color: $light-blue;
        }
    }
}

.typography-key-word {
    @include font-weight-color(bold, $red-chocolate);
}

.box-title-3 {
    font-weight: bold;
    @include font-size-color(1.125rem, $red);
}

.box-title-4 {
    font-weight: bold;
    @include font-size-color(0.9375rem, $light-blue);
    font-style: italic;
}

.box-important-information {
    font-weight: bold;
    @include font-size-color(0.9375rem, $navy-blue);
    font-style: italic;
    text-align: center;
}

#who-we-are {
    color: $navy-blue;
    width: 50%;
}

#role-design-office-enterprise-support-software {
    text-align: justify;
    width: 52%;
}

#role-design-office-enterprise-support {
    font-weight: bold;
}

#site-supervision-execution-direction-goals-software {
    width: 60%;
    text-align: justify;
}

#footer {
    font-family: 'Montserrat',sans-serif;
    @include font-size-color(0.9375rem, $slightly-dark-blue);

    & a {
        text-decoration: none;
        color: $light-blue;

        &:hover {
            color: $red;
            text-decoration: underline;
        }
    }
}

.footer-column-title {
    font-size: 1.5rem;
    font-weight: bold;
}

@mixin interested-by-us-main-title-only-screen {
    .interested-by-us-main-title {    
        @media (min-width: 740px) and (max-width: 965px) {
            font-size: 4rem;
        }
        @media (min-width: 554px) and (max-width: 740px) {
            font-size: 3rem;
        }
        @media (max-width: 554px) {
            font-size: 1.9375rem;
        }
    }
}

@media only screen {

    @include interested-by-us-main-title-only-screen();

    // Smartphone (portrait and landscape) and some Tablets (from 768px)
    @media (min-width: 320px) and (max-width: 929px) {
        // Portrait and Landscape
        #main-title {
            font-size: 2rem;
        }
        
        #who-we-are {
            width: 95%;
        }
        
        #role-design-office-enterprise-support-software, #site-supervision-execution-direction-goals-software {
            width: 90%;
        }

        // Landscape only
        @media (orientation: landscape) {
            // Max width for mobile phone
            @media (max-height: 480px) {
                #main-title {
                    font-size: 2.25rem;
                }
            }
        }

        // Portrait only    
        @media (orientation: portrait) {
            .box-title-3 {
                font-size: 0.9375rem;
            }
        }
    }
    // Some smartphone (until 926px), and Tablets (Portrait and Landscape)
    @media (min-width: 768px) and (max-width: 1280px) {
        // Not smartphone
        @media (min-height: 481px) {
            // Landscape only
            @media (orientation: landscape) {
                // Not smartphone
                @media (min-height: 481px) {
                    #main-title {
                        font-size: 3.5rem;
                    }

                    .main-title-complement {
                        font-size: 2rem;
                    }
                }
            }
            // Portrait only
            @media (orientation: portrait) {
                #main-title {
                    font-size: 2rem;
                }
            }
        }
    }

    
    // Smartphones, Tablets and some laptops
    @media (min-width: 320px) and (max-width: 1280px) {
        .main-title-complement {
            font-size: 1.125rem;
        }
    }

    // Gap between Smartphone and Tablet
    @media (min-width: 930px) and (max-width: 1280px) {
        @media (orientation: landscape) {
            @media (max-height: 481px) {
                #main-title {
                    font-size: 2.5rem;
                }
            }
        }
    }
}