/************
 *   Grid   *
 ************/
/******************
 *   Typography   *
 ******************/
/****************************
 *   Organizational chart   *
 ****************************/
/**************
 *   Remain   *
 **************/
body {
  margin: 0;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, "Calibri", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 1rem;
  text-align: justify;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

#primary-menu li a {
  font-size: 0.875rem;
  color: rgba(31, 142, 209, 0.96);
  font-weight: bold;
  text-transform: uppercase;
  text-decoration: none;
}
#primary-menu li a.activated, #primary-menu li a:hover {
  color: rgb(255, 0, 13);
}

#organizational-chart-content {
  text-align: center;
}

#main-title {
  font-size: 5rem;
  color: rgba(5, 2, 96, 0.96);
  font-weight: bold;
  justify-self: center;
  text-align: center;
  margin: auto 0;
}

.main-title-complement {
  font-size: 2.5rem;
  color: rgba(5, 2, 96, 0.96);
  text-align: center;
  line-height: 1.2;
}

.title-1-with-bullet {
  font-weight: bold;
  font-size: 1.25rem;
  color: rgba(31, 142, 209, 0.96);
}

.title-2 {
  font-weight: bold;
  color: rgba(31, 142, 209, 0.96);
}

.interested-by-us-main-title {
  font-size: 5rem;
  color: rgb(18, 85, 162);
  font-weight: bold;
}

.contact-way-details {
  font-weight: bold;
  color: rgba(31, 142, 209, 0.96);
}

#sent-message-status {
  font-weight: bold;
  font-size: 1.125rem;
}

.input-field-error-message {
  font-size: 0.875rem;
  color: rgb(255, 0, 13);
  float: right;
}

#contact-details {
  font-size: 1.0625rem;
  color: rgba(5, 2, 96, 0.96);
  font-weight: bold;
  text-align: center;
}
#contact-details a {
  font-size: 1.125rem;
  color: rgba(2, 1, 49, 0.96);
}
#contact-details a:hover {
  color: #fff;
}

#organizational-chart-manager {
  color: #fff;
}

#organizational-chart-complement {
  color: rgba(31, 142, 209, 0.96);
}

#cv-manager a {
  text-decoration: none;
  color: #000;
}
#cv-manager a:hover {
  color: rgba(31, 142, 209, 0.96);
}

.typography-key-word {
  font-weight: bold;
  color: rgb(193, 5, 5);
}

.box-title-3 {
  font-weight: bold;
  font-size: 1.125rem;
  color: rgb(255, 0, 13);
}

.box-title-4 {
  font-weight: bold;
  font-size: 0.9375rem;
  color: rgba(31, 142, 209, 0.96);
  font-style: italic;
}

.box-important-information {
  font-weight: bold;
  font-size: 0.9375rem;
  color: rgba(5, 2, 96, 0.96);
  font-style: italic;
  text-align: center;
}

#who-we-are {
  color: rgba(5, 2, 96, 0.96);
  width: 50%;
}

#role-design-office-enterprise-support-software {
  text-align: justify;
  width: 52%;
}

#role-design-office-enterprise-support {
  font-weight: bold;
}

#site-supervision-execution-direction-goals-software {
  width: 60%;
  text-align: justify;
}

#footer {
  font-family: "Montserrat", sans-serif;
  font-size: 0.9375rem;
  color: rgb(18, 85, 162);
}
#footer a {
  text-decoration: none;
  color: rgba(31, 142, 209, 0.96);
}
#footer a:hover {
  color: rgb(255, 0, 13);
  text-decoration: underline;
}

.footer-column-title {
  font-size: 1.5rem;
  font-weight: bold;
}

@media only screen and (min-width: 740px) and (max-width: 965px) {
  .interested-by-us-main-title {
    font-size: 4rem;
  }
}
@media only screen and (min-width: 554px) and (max-width: 740px) {
  .interested-by-us-main-title {
    font-size: 3rem;
  }
}
@media only screen and (max-width: 554px) {
  .interested-by-us-main-title {
    font-size: 1.9375rem;
  }
}
@media only screen and (min-width: 320px) and (max-width: 929px) {
  #main-title {
    font-size: 2rem;
  }
  #who-we-are {
    width: 95%;
  }
  #role-design-office-enterprise-support-software, #site-supervision-execution-direction-goals-software {
    width: 90%;
  }
}
@media only screen and (min-width: 320px) and (max-width: 929px) and (orientation: landscape) and (max-height: 480px) {
  #main-title {
    font-size: 2.25rem;
  }
}
@media only screen and (min-width: 320px) and (max-width: 929px) and (orientation: portrait) {
  .box-title-3 {
    font-size: 0.9375rem;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1280px) and (min-height: 481px) and (orientation: landscape) and (min-height: 481px) {
  #main-title {
    font-size: 3.5rem;
  }
  .main-title-complement {
    font-size: 2rem;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1280px) and (min-height: 481px) and (orientation: portrait) {
  #main-title {
    font-size: 2rem;
  }
}
@media only screen and (min-width: 320px) and (max-width: 1280px) {
  .main-title-complement {
    font-size: 1.125rem;
  }
}
@media only screen and (min-width: 930px) and (max-width: 1280px) and (orientation: landscape) and (max-height: 481px) {
  #main-title {
    font-size: 2.5rem;
  }
}
button {
  background-color: rgb(18, 85, 162);
  font-weight: bold;
  color: #fff;
  font-size: 0.875rem;
  padding: 0.625rem 1.875rem;
  border: none;
  border-radius: 1.25rem;
  cursor: pointer;
}
button:hover {
  background-color: rgba(31, 142, 209, 0.96);
}

#button-form-sending-message {
  width: 30%;
}

@media only screen and (min-width: 320px) and (max-width: 929px) {
  #button-form-sending-message {
    width: 40%;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1280px) and (orientation: portrait) {
  #button-form-sending-message {
    width: 45%;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1280px) and (orientation: landscape) {
  #button-form-sending-message {
    width: 40%;
  }
}
@media only screen and (max-width: 1425px) {
  #icon-navigation button {
    border: none;
    background-color: #fff;
    color: rgba(5, 2, 96, 0.96);
    font-size: 1.7rem;
    font-weight: bold;
    cursor: pointer;
  }
  .navigation.menu-displayed #primary-navigation #button-cancel {
    display: block;
    padding: 0.5rem 0.65rem;
    background-color: rgba(153, 207, 238, 0.33);
    border: 0.2rem solid rgba(5, 2, 96, 0.96);
    border-radius: 2rem;
    font-size: 1rem;
    cursor: pointer;
    justify-self: right;
    margin: 1rem 0.8rem 0 0;
  }
  .navigation.menu-displayed #primary-navigation #button-cancel img {
    width: 1rem;
  }
}
.contact-way-icon {
  justify-self: center;
  padding: 0.5rem 0 0.5rem 0.5rem;
}
.contact-way-icon img {
  height: 1rem;
  width: 1rem;
}

#postal-address-contact-way-icon img {
  height: 1.15rem;
  width: 0.9rem;
}

.fb-contact-way-icon {
  height: 1.5rem;
  width: 1.5rem;
}
.fb-contact-way-icon img {
  height: 100%;
  width: 100%;
}
.fb-contact-way-icon #icon-2-facebook {
  display: none;
}
.fb-contact-way-icon:hover #icon-1-facebook {
  display: none;
}
.fb-contact-way-icon:hover #icon-2-facebook {
  display: block;
  height: 1.58rem;
  width: 1.5rem;
}

#cv-manager img {
  height: 1.375rem;
  width: 1.11rem;
  margin: 0 0 0 0.125rem;
}

#sent-message-status img {
  height: 2.1875rem;
  width: 2.1875rem;
}

#logo-betis-header img {
  height: 6.5625rem;
  width: 9.31875rem;
}

.study-image-example {
  justify-self: center;
}
.study-image-example img {
  width: 100%;
}

.study-big-image-example {
  width: 73%;
}

#building-permit-request-image {
  width: 52%;
}

#building-plan-2D-image {
  width: 65%;
}

#building-plan-3D-image {
  width: 85%;
}

@media only screen and (min-width: 320px) and (max-width: 929px) {
  .study-image-example {
    width: 95% !important;
  }
}
@media only screen and (min-width: 320px) and (max-width: 929px) and (orientation: portrait) {
  #building-permit-request-image, #building-plan-2D-image, #building-plan-3D-image {
    width: 100% !important;
  }
}
input, textarea {
  margin: 0.125rem 0 0.5rem 0;
  padding: 0.5rem;
  width: 100%;
  border: 0.0625rem solid #ccc;
  border-radius: 0.25rem;
  box-sizing: border-box;
  resize: vertical;
}

#design-studies-network-extension ol {
  margin-top: 0;
  margin-bottom: 0;
}
#design-studies-network-extension ol[type=A] > li {
  margin: 1rem 0 0 0;
}
#design-studies-network-extension ol[type=A] > li::marker {
  font-weight: bold;
}
#design-studies-network-extension ul {
  list-style-type: disc;
}

@media only screen and (max-width: 1425px) {
  .navigation.menu-displayed #primary-navigation ul {
    background-color: rgba(5, 2, 96, 0.96);
    margin: 1rem;
    padding: 0.375rem;
    border-radius: 0.5rem;
  }
  .navigation.menu-displayed #primary-navigation ul li {
    display: inline-block;
    margin: 0;
    padding: 0.125rem 0.5rem;
    height: 3.8rem;
    background-color: #fff;
    border-radius: 0.5rem;
  }
}
.down-arrow {
  height: 100%;
  width: 0.3125rem;
  float: left;
}

.vertical-line {
  border-left: 0.125rem solid rgba(5, 2, 96, 0.96);
  height: 2.6875rem;
  margin: 0;
  padding: 0;
}

.down-arrow-head {
  height: 0;
  width: 0;
  border-style: solid;
  border-width: 0.4375rem;
  border-color: rgba(5, 2, 96, 0.96) transparent transparent transparent;
  margin: 0 0 0 -0.375rem;
  padding: 0;
}

#main-title-area {
  background-image: url("../images/Betis-logo-watermark.png");
  background-repeat: no-repeat;
  background-size: 36.25rem 25.4375rem;
  background-position: center;
  background-attachment: fixed;
  border-top: 0.125rem solid rgba(5, 2, 96, 0.96);
  border-bottom: 0.125rem solid rgba(5, 2, 96, 0.96);
}

.main-title-complement {
  justify-self: center;
  width: 80%;
}

.box-title-3 {
  border: 0.125rem solid #000;
  width: 47%;
  margin: 0 0 0 10.625rem;
  padding: 0.5rem 0.625rem;
}

.box-title-4 {
  border: 0.25rem solid rgba(31, 142, 209, 0.96);
  position: relative;
  padding: 1.375rem 0.625rem;
  width: 18%;
}
.box-title-4::before {
  content: " ";
  position: absolute;
  border-left: 0.125rem solid rgba(31, 142, 209, 0.96);
  right: 0.375rem;
  top: 0.375rem;
  left: -0.4375rem;
  bottom: 0.375rem;
}

.box-important-information {
  border: 0.25rem solid rgba(31, 142, 209, 0.96);
  position: relative;
  padding: 1.375rem 0.625rem;
  width: 30%;
  justify-self: center;
}
.box-important-information::before {
  content: " ";
  position: absolute;
  border-left: 0.125rem solid rgba(31, 142, 209, 0.96);
  right: 0.375rem;
  top: 0.375rem;
  left: -0.4375rem;
  bottom: 0.375rem;
}

@media only screen and (min-width: 320px) and (max-width: 929px) and (orientation: landscape) and (max-height: 480px) {
  #main-title-area {
    background-size: 22.875rem 15.375rem;
  }
  .box-title-3 {
    margin: 0 0 0 auto;
  }
}
@media only screen and (min-width: 320px) and (max-width: 929px) and (orientation: landscape) and (max-height: 480px) and (min-width: 659px) and (max-width: 700px) {
  .box-title-3 {
    width: 80% !important;
  }
}
@media only screen and (min-width: 320px) and (max-width: 929px) and (orientation: landscape) and (max-height: 480px) and (min-width: 701px) and (max-width: 730px) {
  .box-title-3 {
    width: 76% !important;
  }
}
@media only screen and (min-width: 320px) and (max-width: 929px) and (orientation: landscape) and (max-height: 480px) and (min-width: 731px) and (max-width: 780px) {
  .box-title-3 {
    width: 73% !important;
  }
}
@media only screen and (min-width: 320px) and (max-width: 929px) and (orientation: landscape) and (max-height: 480px) and (min-width: 781px) and (max-width: 820px) {
  .box-title-3 {
    width: 69% !important;
  }
}
@media only screen and (min-width: 320px) and (max-width: 929px) and (orientation: landscape) and (max-height: 480px) and (min-width: 821px) and (max-width: 880px) {
  .box-title-3 {
    width: 66% !important;
  }
}
@media only screen and (min-width: 320px) and (max-width: 929px) and (orientation: landscape) and (max-height: 480px) and (min-width: 881px) and (max-width: 929px) {
  .box-title-3 {
    width: 62% !important;
  }
}
@media only screen and (min-width: 320px) and (max-width: 929px) and (orientation: landscape) and (max-height: 480px) {
  .box-title-4 {
    width: 61% !important;
  }
  .presentation-little.box-title-4 {
    width: 32% !important;
  }
  .box-important-information {
    width: 68% !important;
  }
}
@media only screen and (min-width: 320px) and (max-width: 929px) and (orientation: portrait) and (min-width: 320px) and (max-width: 360px) {
  #main-title-area {
    background-size: 19.875rem 13.375rem;
  }
}
@media only screen and (min-width: 320px) and (max-width: 929px) and (orientation: portrait) and (min-width: 360px) and (max-width: 380px) {
  #main-title-area {
    background-size: 22.875rem 15.375rem;
  }
}
@media only screen and (min-width: 320px) and (max-width: 929px) and (orientation: portrait) and (min-width: 380px) and (max-width: 400px) {
  #main-title-area {
    background-size: 23.875rem 16.375rem;
  }
}
@media only screen and (min-width: 320px) and (max-width: 929px) and (orientation: portrait) and (min-width: 400px) and (max-width: 420px) {
  #main-title-area {
    background-size: 25.875rem 18.375rem;
  }
}
@media only screen and (min-width: 320px) and (max-width: 929px) and (orientation: portrait) and (min-width: 420px) {
  #main-title-area {
    background-size: 26.875rem 19.375rem;
  }
}
@media only screen and (min-width: 320px) and (max-width: 929px) and (orientation: portrait) {
  .box-title-3 {
    width: auto !important;
    margin: 0;
  }
  .box-title-4 {
    width: auto !important;
  }
  .presentation-little.box-title-4 {
    width: 60% !important;
  }
  .box-important-information {
    width: 85% !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1280px) and (min-height: 481px) and (orientation: portrait) {
  .box-title-3 {
    margin: 0 0 0 12.625rem;
    width: 57% !important;
  }
  .box-title-4 {
    width: 52% !important;
  }
  .presentation-little.box-title-4 {
    width: 30% !important;
  }
  .box-important-information {
    width: 60% !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1280px) and (min-height: 481px) and (orientation: landscape) {
  .box-title-3 {
    width: 55% !important;
  }
  .box-title-4 {
    width: 42% !important;
  }
  .presentation-little.box-title-4 {
    width: 25% !important;
  }
  .box-important-information {
    width: 64% !important;
  }
}
#body-header {
  height: 7.1875rem;
  width: 100%;
  top: 0;
}

#main-content {
  line-height: 1.5;
}

#navigation-bar {
  line-height: 4.5rem;
}

#primary-navigation {
  display: block;
}
#primary-navigation #button-cancel {
  display: none;
}

#icon-navigation {
  display: none;
}

#primary-menu li {
  display: inline-block;
  margin: 0 4.375rem 0 0;
  padding: 0.125rem;
  vertical-align: middle;
}
#primary-menu li a {
  display: block;
}

@media only screen and (max-width: 1425px) {
  #primary-navigation {
    display: none;
  }
  #icon-navigation {
    display: block;
  }
  .navigation.menu-displayed #primary-navigation {
    width: 100%;
    position: fixed;
    min-height: 52.5rem;
    right: 0;
    background-color: #fff;
  }
}
@media only screen and (max-width: 1425px) and (max-width: 345px) {
  .navigation.menu-displayed #primary-navigation #wrap-button-cancel-primary-menu {
    width: 19rem;
    justify-self: center;
  }
}
@media only screen and (max-width: 1425px) and (min-width: 367px) {
  .navigation.menu-displayed #primary-navigation #wrap-button-cancel-primary-menu {
    width: 22rem;
    justify-self: center;
  }
}
@media only screen and (max-width: 1425px) and (orientation: landscape) {
  .navigation.menu-displayed #primary-navigation #wrap-button-cancel-primary-menu {
    width: 35rem;
    justify-self: center;
  }
}
.simple-grid-one-column, #wrap-button-cancel-primary-menu, #wrapper-main-title-and-complement, .grid-page,
#organizational-chart-complement, #role-design-office-enterprise-support-software {
  display: grid;
  grid-template-columns: 1fr;
}

.simple-grid-one-column-gap-one, #tenant-entry, #design-studies-network-extension, #work-plans, #work-plans-1-500, #edm-network-legend,
#public-lighting-execution-plan, #electricity-lot-execution-plan, #electricity-lot-execution-plan-2,
#electricity-lot-execution-plan-3, #electricity-lot-execution-plan-4, #network-execution-plan,
#electricity-building-execution-studies-plan, #execution-direction-goals,
#site-supervision-execution-direction-goals-software, #site-supervision-example,
#site-supervision-example-2, #site-supervision-example-3, #footer {
  display: grid;
  grid-template-columns: 1fr;
  gap: 1rem;
}

#body-container {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(2, minmax(7rem, auto));
  gap: 3rem;
}

#body-header {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
}

#logo-betis-header {
  grid-column: 3/4;
  grid-row: 1;
}

#navigation-bar {
  grid-column: 4/12;
  grid-row: 1;
}

.main {
  display: grid;
  grid-template-columns: 1fr;
  grid-auto-rows: minmax(27rem, auto);
}

#wrapper-main-title-area {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 0 27rem 8rem;
}
@media only screen and (min-width: 320px) and (max-width: 929px) and (orientation: portrait) and (min-height: 781px) and (max-height: 869px) {
  #wrapper-main-title-area {
    grid-template-rows: 3rem 27rem 8rem;
  }
}
@media only screen and (min-width: 320px) and (max-width: 929px) and (orientation: portrait) and (min-height: 870px) and (max-height: 900px) {
  #wrapper-main-title-area {
    grid-template-rows: 4rem 27rem 8rem;
  }
}
@media only screen and (min-width: 320px) and (max-width: 929px) and (orientation: portrait) and (min-height: 900px) and (max-height: 929px) {
  #wrapper-main-title-area {
    grid-template-rows: 5rem 27rem 10rem;
  }
}
@media only screen and (min-width: 320px) and (max-width: 929px) and (orientation: landscape) and (max-height: 359px) {
  #wrapper-main-title-area {
    grid-template-rows: 0.25rem 12.5rem 1rem;
  }
}
@media only screen and (min-width: 320px) and (max-width: 929px) and (orientation: landscape) and (min-height: 359px) and (max-height: 378px) {
  #wrapper-main-title-area {
    grid-template-rows: 0.25rem 12.5rem 1rem;
  }
}
@media only screen and (min-width: 320px) and (max-width: 929px) and (orientation: landscape) and (min-height: 379px) and (max-height: 398px) {
  #wrapper-main-title-area {
    grid-template-rows: 0.25rem 15rem 2rem;
  }
}
@media only screen and (min-width: 320px) and (max-width: 929px) and (orientation: landscape) and (min-height: 399px) and (max-height: 418px) {
  #wrapper-main-title-area {
    grid-template-rows: 0.25rem 16.25rem 3rem;
  }
}
@media only screen and (min-width: 320px) and (max-width: 929px) and (orientation: landscape) and (min-height: 419px) and (max-height: 438px) {
  #wrapper-main-title-area {
    grid-template-rows: 0.25rem 17.5rem 3rem;
  }
}
@media only screen and (min-width: 320px) and (max-width: 929px) and (orientation: landscape) and (min-height: 439px) and (max-height: 450px) {
  #wrapper-main-title-area {
    grid-template-rows: 0.25rem 18.5rem 3rem;
  }
}
@media only screen and (min-width: 320px) and (max-width: 929px) and (orientation: landscape) and (min-height: 451px) and (max-height: 480px) {
  #wrapper-main-title-area {
    grid-template-rows: 0.25rem 20rem 3rem;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1280px) and (orientation: portrait) and (min-height: 781px) and (max-height: 869px) {
  #wrapper-main-title-area {
    grid-template-rows: 3rem 27rem 8rem;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1280px) and (orientation: portrait) and (min-height: 870px) and (max-height: 900px) {
  #wrapper-main-title-area {
    grid-template-rows: 4rem 27rem 8rem;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1280px) and (orientation: portrait) and (min-height: 900px) and (max-height: 929px) {
  #wrapper-main-title-area {
    grid-template-rows: 5rem 27rem 10rem;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1280px) and (orientation: portrait) and (min-height: 930px) and (max-height: 965px) {
  #wrapper-main-title-area {
    grid-template-rows: 6rem 27rem 12rem;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1280px) and (orientation: portrait) and (min-height: 966px) and (max-height: 995px) {
  #wrapper-main-title-area {
    grid-template-rows: 7rem 27rem 12rem;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1280px) and (orientation: portrait) and (min-height: 995px) and (max-height: 1025px) {
  #wrapper-main-title-area {
    grid-template-rows: 8rem 27rem 12rem;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1280px) and (orientation: portrait) and (min-height: 1026px) and (max-height: 1056px) {
  #wrapper-main-title-area {
    grid-template-rows: 9rem 27rem 12rem;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1280px) and (orientation: portrait) and (min-height: 1056px) and (max-height: 1100px) {
  #wrapper-main-title-area {
    grid-template-rows: 10rem 27rem 12rem;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1280px) and (orientation: portrait) and (min-height: 1100px) and (max-height: 1150px) {
  #wrapper-main-title-area {
    grid-template-rows: 11.5rem 27rem 12rem;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1280px) and (orientation: portrait) and (min-height: 1150px) and (max-height: 1200px) {
  #wrapper-main-title-area {
    grid-template-rows: 13rem 27rem 17rem;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1280px) and (orientation: portrait) and (min-height: 1200px) and (max-height: 1240px) {
  #wrapper-main-title-area {
    grid-template-rows: 14.5rem 27rem 19rem;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1280px) and (orientation: portrait) and (min-height: 1240px) {
  #wrapper-main-title-area {
    grid-template-rows: 16rem 27rem 20rem;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1280px) and (orientation: landscape) and (min-height: 481px) and (max-height: 520px) {
  #wrapper-main-title-area {
    grid-template-rows: 1.5rem 21rem 5rem;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1280px) and (orientation: landscape) and (min-height: 521px) and (max-height: 590px) {
  #wrapper-main-title-area {
    grid-template-rows: 1.5rem 23.5rem 5rem;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1280px) and (orientation: landscape) and (min-height: 591px) and (max-height: 615px) {
  #wrapper-main-title-area {
    grid-template-rows: 1.5rem 25.5rem 5rem;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1280px) and (orientation: landscape) and (min-height: 616px) and (max-height: 787px) {
  #wrapper-main-title-area {
    grid-template-rows: 1.5rem 26rem 5rem;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1280px) and (orientation: landscape) and (min-height: 788px) and (max-height: 807px) {
  #wrapper-main-title-area {
    grid-template-rows: 2rem 26rem 5rem;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1280px) and (orientation: landscape) and (min-height: 808px) {
  #wrapper-main-title-area {
    grid-template-rows: 2rem 27rem 5rem;
  }
}
@media only screen and (min-width: 481px) and (max-width: 767px) and (orientation: landscape) and (min-height: 481px) and (max-height: 520px) {
  #wrapper-main-title-area {
    grid-template-rows: 1.5rem 21rem 5rem;
  }
}
@media only screen and (min-width: 481px) and (max-width: 767px) and (orientation: landscape) and (min-height: 521px) and (max-height: 590px) {
  #wrapper-main-title-area {
    grid-template-rows: 1.5rem 23.5rem 5rem;
  }
}
@media only screen and (min-width: 481px) and (max-width: 767px) and (orientation: landscape) and (min-height: 591px) and (max-height: 615px) {
  #wrapper-main-title-area {
    grid-template-rows: 1.5rem 25.5rem 5rem;
  }
}
@media only screen and (min-width: 481px) and (max-width: 767px) and (orientation: landscape) and (min-height: 616px) and (max-height: 787px) {
  #wrapper-main-title-area {
    grid-template-rows: 1.5rem 26rem 5rem;
  }
}
@media only screen and (min-width: 481px) and (max-width: 767px) and (orientation: landscape) and (min-height: 788px) and (max-height: 807px) {
  #wrapper-main-title-area {
    grid-template-rows: 2rem 26rem 5rem;
  }
}
@media only screen and (min-width: 481px) and (max-width: 767px) and (orientation: landscape) and (min-height: 808px) {
  #wrapper-main-title-area {
    grid-template-rows: 2rem 27rem 5rem;
  }
}

#main-title-area {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 20% 60% 20%;
}

#who-we-are {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 14rem auto auto 13rem;
  gap: 1.5rem;
}

#homepage-technician-tasks-list {
  display: grid;
  grid-template-columns: 1fr;
  gap: 0.125rem;
}
#homepage-technician-tasks-list li {
  display: grid;
  display: grid;
  grid-template-columns: 4% 96%;
}

#interested-by-us {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 8rem auto auto 0;
  gap: 1.5rem;
}

#contact-us {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

#contact-ways {
  display: grid;
  grid-template-columns: 6% 72%;
  grid-template-rows: 0.5rem auto auto auto auto 9rem;
  column-gap: 0.75rem;
  row-gap: 1rem;
}

#form-sending-message {
  display: grid;
  grid-template-columns: 90%;
  gap: 0.0625rem;
}

#contact-details {
  display: grid;
  grid-template-columns: 1fr;
  grid-auto-rows: auto 0.5rem auto auto auto;
}

#presentation-enterprise-specialisation {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 12rem auto auto auto auto 10rem;
  gap: 1.5rem;
}

#presentation-legal-info-expertise {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 15rem auto auto auto auto auto auto 15rem;
  gap: 1.5rem;
}

.presentation-legal-info {
  display: grid;
  grid-template-columns: 2% 30%;
  gap: 0.125rem;
}

#organizational-chart {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 4rem auto auto auto auto auto 3rem;
  gap: 1.5rem;
  justify-items: center;
}

#organizational-chart-complement {
  gap: 0.85rem;
}

#design-study-building-permit {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 8rem auto auto auto auto auto 0.5rem;
  gap: 1rem;
}

.title-1-with-bullet {
  display: grid;
  grid-template-columns: 2% 95%;
  gap: 1rem;
}

#building-plan-2D {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 7rem auto auto auto 3rem;
  gap: 1rem;
}

#building-plan-3D {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 15rem auto auto auto 15rem;
  gap: 2rem;
}

#electricity-network-connection {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 5rem auto auto auto auto auto auto 2rem;
  gap: 2rem;
}

#company-choice {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 5rem auto auto auto auto auto 3rem;
  gap: 2rem;
}

#tenant-entry {
  grid-template-rows: 15rem auto auto auto auto 15rem;
}

#design-studies-network-extension {
  grid-template-rows: 5rem auto auto auto 4rem;
  gap: 0.5rem;
}

#role-design-office-enterprise-support-software {
  grid-template-rows: 8rem auto auto auto auto 4rem;
  gap: 0.5rem;
}

#role-design-office-enterprise-support {
  display: grid;
  grid-template-columns: 1% 99%;
  gap: 0.9375rem;
}

#execution-studies-software {
  display: grid;
  grid-template-columns: 2% 98%;
  gap: 0.0625rem;
}

#public-lighting-execution-plan, #electricity-lot-execution-plan-2,
#work-plans {
  grid-template-rows: 4.2rem auto 3rem;
}

#electricity-lot-execution-plan, #work-plans-1-500 {
  grid-template-rows: 15rem auto 15rem;
}

#site-supervision-example {
  grid-template-rows: 10rem auto 8rem;
}

#site-supervision-example-2 {
  grid-template-rows: 12rem auto 6rem;
}

#site-supervision-example-3 {
  grid-template-rows: 17rem auto 3rem;
}

#electricity-lot-execution-plan-3, #electricity-lot-execution-plan-4 {
  grid-template-rows: 8rem auto 3rem;
}

#electricity-building-execution-studies-plan {
  grid-template-rows: 15rem auto auto auto 15rem;
  gap: 0;
}

#transformation-post-connection-box {
  display: grid;
  grid-template-columns: 1fr;
  gap: 0.75rem;
}

#site-supervision-execution-direction-goals-software {
  grid-template-rows: 8rem auto auto auto auto 3rem;
}

#site-supervision-software {
  display: grid;
  grid-template-columns: 2% 98%;
  grid-template-rows: 2rem auto auto 2rem;
  gap: 0.0625rem;
}

#box-message {
  display: grid;
  grid-template-rows: 4rem;
  gap: 1rem;
  justify-items: center;
}

#footer {
  grid-template-rows: 5rem auto;
}

#footer-content {
  display: grid;
  grid-template-columns: 2fr 1fr 1fr 0.5fr;
  grid-template-rows: 20% 60% 20%;
}

#footer-company, #footer-services {
  display: grid;
  grid-template-columns: 1fr;
  grid-auto-rows: max-content;
  gap: 0.7rem;
}

@media only screen and (min-width: 320px) and (max-width: 929px) {
  .presentation-legal-info {
    grid-template-columns: 5% 90%;
  }
}
@media only screen and (min-width: 320px) and (max-width: 929px) and (orientation: landscape) {
  #body-container {
    gap: 0;
  }
  .main {
    grid-template-rows: minmax(16rem, auto);
  }
}
@media only screen and (min-width: 320px) and (max-width: 929px) and (orientation: landscape) and (max-height: 480px) {
  #who-we-are {
    grid-template-rows: auto auto auto 15rem;
    gap: 0.5rem;
  }
  #presentation-enterprise-specialisation {
    grid-template-rows: auto auto auto auto auto 2rem;
    gap: 0.5rem;
  }
}
@media only screen and (min-width: 320px) and (max-width: 929px) and (orientation: landscape) {
  #footer-content {
    display: grid;
    grid-template-columns: 1.5fr 1fr 1.5fr 0;
    grid-template-rows: none;
  }
}
@media only screen and (min-width: 320px) and (max-width: 929px) and (orientation: portrait) {
  #who-we-are {
    grid-template-rows: 8rem auto auto 13rem;
  }
  #contact-ways {
    grid-template-rows: 0.5rem auto auto auto auto 2rem;
  }
  #presentation-enterprise-specialisation {
    grid-template-rows: 10rem auto auto auto auto 10rem;
  }
  #organizational-chart {
    grid-template-rows: 5rem auto auto auto auto auto 5rem;
  }
  #design-study-building-permit, #building-plan-3D {
    gap: 2rem;
  }
  #building-plan-2D {
    gap: 3rem;
  }
  #network-execution-plan {
    grid-template-rows: 2rem auto 15rem;
  }
  #role-design-office-enterprise-support-software {
    gap: 1.5rem;
  }
  #site-supervision-example-3 {
    grid-template-rows: 17rem auto 15rem;
  }
}
@media only screen and (min-width: 320px) and (max-width: 929px) and (orientation: portrait) and (max-height: 929px) {
  #contact-us {
    grid-template-columns: 1fr;
  }
  #footer-content {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: none;
  }
}
@media only screen and (max-width: 1280px) and (min-width: 768px) and (orientation: portrait) {
  #who-we-are {
    grid-template-rows: 7rem auto auto 13rem;
  }
  #presentation-enterprise-specialisation {
    grid-template-rows: 16rem auto auto auto auto 10rem;
  }
  #organizational-chart {
    grid-template-rows: 12rem auto auto auto auto auto 18rem;
  }
  #network-execution-plan {
    grid-template-rows: 3rem auto 15rem;
  }
  #site-supervision-example-3 {
    grid-template-rows: 17rem auto 18rem;
  }
}
@media only screen and (max-width: 1280px) and (min-width: 768px) and (orientation: landscape) {
  #organizational-chart {
    grid-template-rows: 12rem auto auto auto auto auto 6rem;
  }
  #network-execution-plan {
    grid-template-rows: 3rem auto 5rem;
  }
}
@media only screen and (max-width: 1280px) and (orientation: portrait) {
  #footer-content {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: none;
  }
}
@media only screen and (max-width: 1425px) {
  .navigation {
    grid-column: 10/-1 !important;
    justify-self: center;
  }
  .navigation.menu-displayed #primary-navigation {
    display: grid;
    grid-template-columns: 1fr;
    grid-auto-rows: max-content;
  }
  .navigation.menu-displayed #primary-navigation ul {
    display: grid;
    grid-template-columns: 1fr;
    gap: 0.3rem;
  }
}
@media only screen and (max-width: 1425px) and (orientation: landscape) {
  .navigation.menu-displayed #primary-navigation ul {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 0.3rem;
  }
}
.fixed-block {
  width: 100%;
  position: fixed;
  background: #fff;
  z-index: 9995;
  left: 0;
  right: 0;
}

.sticky-block {
  height: 2rem;
  position: sticky;
  background-color: #fff;
  bottom: 0;
}

.sticky-page {
  position: sticky;
  top: 2rem;
}

.after-sticky-page {
  background-color: #fff;
  z-index: 1;
}

.colour-gradient-progressively-opened-box {
  background-image: linear-gradient(0deg, rgba(153, 207, 238, 0.33), rgba(102, 184, 230, 0.61) 50% 87%, rgba(84, 176, 225, 0.68));
  background-attachment: fixed;
  background-size: 100% 44rem;
  background-repeat: no-repeat;
}

#execution-studies-software {
  margin: 1rem 0 0 0;
}

.grid-page-content {
  width: 75%;
  justify-self: center;
}

@media only screen and (min-width: 320px) and (max-width: 767px) {
  .grid-page-content {
    width: 98%;
  }
}
@media only screen and (min-width: 767px) and (max-width: 920px) {
  .grid-page-content {
    width: 96%;
  }
}
@media only screen and (min-width: 920px) and (max-width: 1020px) {
  .grid-page-content {
    width: 94%;
  }
}
@media only screen and (min-width: 1020px) and (max-width: 1099px) {
  .grid-page-content {
    width: 92%;
  }
}
@media only screen and (min-width: 1099px) and (max-width: 1280px) {
  .grid-page-content {
    width: 90%;
  }
}
@media only screen and (min-width: 320px) and (max-width: 929px) and (orientation: portrait) and (max-height: 929px) {
  .sticky-block {
    height: 14rem;
  }
  .aboutus-portrait.sticky-page {
    top: 0;
  }
  .design-sticky.sticky-page {
    top: -22rem;
  }
}
@media only screen and (min-width: 320px) and (max-width: 929px) and (orientation: portrait) and (max-height: 929px) and (max-width: 380px) {
  .design-sticky.sticky-page {
    top: -19rem;
  }
}
@media only screen and (min-width: 320px) and (max-width: 929px) and (orientation: portrait) and (max-height: 929px) {
  .execution-sticky.sticky-page {
    top: -36rem;
  }
  .supervision-sticky.sticky-page {
    top: -15rem;
  }
  .supervision-sticky-image.sticky-page {
    top: 7rem;
  }
}
@media only screen and (min-width: 320px) and (max-width: 929px) and (orientation: landscape) and (max-height: 480px) {
  .aboutus-landscape.sticky-page {
    top: -5rem;
  }
  .presentation-sticky.sticky-page {
    top: -5rem;
  }
  .design-sticky.sticky-page, .execution-sticky.sticky-page {
    top: -32rem;
  }
  .supervision-sticky.sticky-page {
    top: -28rem;
  }
  .supervision-sticky-image.sticky-page {
    top: -15rem;
  }
  .smartphone-landscape.sticky-block {
    height: 4rem;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1280px) and (orientation: portrait) {
  .sticky-block {
    height: 24rem;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1280px) and (orientation: portrait) and (min-height: 1088px) and (max-height: 1152px) {
  .sticky-block {
    height: 28rem;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1280px) and (orientation: portrait) and (min-height: 1152px) and (max-height: 1182px) {
  .sticky-block {
    height: 30rem;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1280px) and (orientation: portrait) and (min-height: 1182px) and (max-height: 1215px) {
  .sticky-block {
    height: 32rem;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1280px) and (orientation: portrait) and (min-height: 1215px) and (max-height: 1243px) {
  .sticky-block {
    height: 34rem;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1280px) and (orientation: portrait) and (min-height: 1244px) {
  .sticky-block {
    height: 37rem;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1280px) and (min-height: 481px) and (orientation: portrait) {
  .supervision-sticky.sticky-page {
    top: 5rem;
  }
  .aboutus-portrait.sticky-page {
    top: 0;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1280px) and (min-height: 481px) and (orientation: landscape) {
  .sticky-block {
    height: 9rem;
  }
  .aboutus-landscape.sticky-page {
    top: 0;
  }
}
#interested-by-us {
  background-color: rgba(0, 228, 25, 0.1215686275);
  border-radius: 0.25rem;
}

.contact-way-details {
  border: 0.0625rem solid #000;
  padding: 0.3125rem;
  border-radius: 0.25rem;
}

#mail-address-contact-way {
  width: 42%;
}

#portable-contact-way {
  width: 30%;
}

#fb-contact-way {
  width: 67%;
}
#fb-contact-way a {
  text-decoration: none;
}
#fb-contact-way a:hover {
  color: rgba(5, 2, 96, 0.96);
}

@media only screen and (min-width: 320px) and (max-width: 929px) {
  #mail-address-contact-way {
    width: 65%;
  }
  #portable-contact-way {
    width: 45%;
  }
}
@media only screen and (min-width: 320px) and (max-width: 929px) and (orientation: portrait) {
  #fb-contact-way {
    width: 97%;
  }
}
@media only screen and (min-width: 320px) and (max-width: 929px) and (orientation: landscape) {
  #fb-contact-way {
    width: 98%;
  }
}
@media only screen and (min-width: 767px) and (max-width: 1280px) and (orientation: portrait) {
  #fb-contact-way {
    width: 99%;
  }
}
@media only screen and (min-width: 767px) and (max-width: 1280px) and (orientation: landscape) {
  #fb-contact-way {
    width: 82%;
  }
}
@media only screen and (orientation: landscape) and (min-width: 929px) and (max-width: 1075px) {
  #mail-address-contact-way {
    width: 60%;
  }
  #portable-contact-way {
    width: 45%;
  }
}
#footer {
  height: 95vh;
  width: 98.5vw;
  justify-self: center;
  margin: 0 0 0.15rem 0;
}

#footer-content {
  border: 0.1875rem solid rgba(5, 2, 96, 0.96);
  border-radius: 0.5rem;
}

#footer-logo-betis {
  background-image: url("../images/Betis-logo-footer.png");
  background-repeat: no-repeat;
  background-size: 25rem 17.5625rem;
}

#footer-betis-social-networks {
  justify-self: right;
}

@media only screen and (min-width: 320px) and (max-width: 1280px) {
  #footer {
    margin: 0 0 0.5rem 0;
  }
  #footer-content {
    padding: 0 0.5rem;
  }
}
@media only screen and (min-width: 320px) and (max-width: 1280px) and (orientation: portrait) {
  #footer {
    width: 97vw;
  }
  #footer-logo-betis {
    height: 32vh;
    background-image: url("../images/Betis-logo-footer-smartphone.png");
    background-size: 18.75rem 13.1875rem;
    background-position: center;
  }
  #footer-company {
    height: 22vh;
  }
  #footer-betis-social-networks {
    justify-self: left;
  }
}
@media only screen and (min-width: 320px) and (max-width: 1280px) and (orientation: portrait) and (min-height: 930px) {
  #footer {
    width: 95vw;
    padding: 0 1rem;
  }
}
@media only screen and (min-width: 320px) and (max-width: 1280px) and (orientation: portrait) and (max-height: 759px) {
  #footer-logo-betis {
    background-image: url("../images/Betis-logo-footer-little-smartphone.png");
    background-size: 12.5rem 8.8125rem;
  }
}
@media only screen and (min-width: 320px) and (max-width: 1280px) and (orientation: landscape) {
  #footer {
    height: 80vh;
    width: 98vw;
  }
}
@media only screen and (min-width: 320px) and (max-width: 1280px) and (orientation: landscape) and (max-height: 480px) {
  #footer-logo-betis {
    height: 10rem;
    background-image: url("../images/Betis-logo-footer-little-smartphone.png");
    background-size: 12.5rem 8.8125rem;
  }
  #footer-content {
    height: 62vh;
  }
  #footer {
    margin: 0 0 3rem 0;
  }
}
#organizational-chart-content {
  background-color: rgba(153, 207, 238, 0.33);
  height: 13.125rem;
  width: 55%;
  margin: auto;
}

#organizational-chart-manager {
  background-color: rgb(0, 0, 255);
  width: 8.75rem;
  margin: auto;
}

#organizational-chart-main {
  height: 8.125rem;
  margin: 0.9375rem 0 0 0;
}

.organizational-chart-links-between-manager-employees {
  height: 3.125rem;
  width: 5rem;
  margin: auto;
}

.organizational-chart-employees {
  height: 3.125rem;
  width: 15.625rem;
  margin: auto;
}

.organizational-chart-employee-1 {
  float: left;
  background-color: #3fd2e9;
  color: #fff;
  width: 6.875rem;
}

.organizational-chart-employee-2 {
  float: right;
  background-color: #3fd2e9;
  color: rgba(5, 2, 96, 0.96);
  width: 6.875rem;
}

@media only screen and (min-width: 320px) and (max-width: 929px) and (orientation: portrait) {
  #organizational-chart {
    width: 98%;
  }
  #organizational-chart-content {
    width: 100%;
  }
}
@media only screen and (min-width: 320px) and (max-width: 929px) and (orientation: landscape) {
  #organizational-chart {
    width: 90%;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1280px) and (min-height: 481px) and (orientation: landscape) {
  #organizational-chart {
    width: 60%;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1280px) and (min-height: 481px) and (orientation: portrait) {
  #organizational-chart {
    width: 50%;
  }
}
#contact-details {
  background-color: rgba(14, 150, 228, 0.72);
  height: auto;
  width: 25%;
  padding: 0.625rem 2.1875rem;
  justify-self: center;
}

.presentation-legal-info-title {
  font-weight: bold;
}

.presentation-legal-info-title-bullet::before {
  content: "";
  border-color: transparent #000;
  border-style: solid;
  border-width: 0.35em 0 0.35em 0.45em;
  display: block;
  height: 0;
  width: 0;
  position: relative;
  left: 0;
  top: 0.45em;
}

#presentation-job-domain-list ul {
  list-style: none;
}
#presentation-job-domain-list ul li::before {
  content: "";
  border-color: transparent #000;
  border-style: solid;
  border-width: 0.35em 0 0.35em 0.45em;
  display: block;
  height: 0;
  width: 0;
  position: relative;
  left: -1em;
  top: 1.15em;
}

@media only screen and (min-width: 320px) and (max-width: 929px) and (orientation: portrait) {
  #contact-details {
    width: 70%;
  }
}
@media only screen and (min-width: 320px) and (max-width: 929px) and (orientation: landscape) {
  #contact-details {
    width: 35%;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1280px) and (min-height: 481px) and (orientation: portrait) {
  #contact-details {
    width: 35%;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1280px) and (min-height: 481px) and (orientation: landscape) {
  #contact-details {
    width: 28%;
  }
}

