#navigation-bar {
    line-height: 4.5rem;
}

#primary-navigation {
    display: block;

    & #button-cancel {
        display: none;
    }
}

#icon-navigation {
    display: none;
}

#primary-menu {
    & li {
        display: inline-block;
        @include margin-padding(0 4.375rem 0 0, 0.125rem);
        vertical-align: middle;
    
        & a {
            display: block;
        }
    }
}

// From 1426px, the nav bar is well displayed -> configure the display under 1426px
@media only screen and (max-width: 1425px) {

    #primary-navigation {
        display: none;
    }

    #icon-navigation {
        display: block;
    }

    .navigation {
        &.menu-displayed {
            & #primary-navigation {
                width: 100%;
                position: fixed;
                min-height: 52.5rem;
                right: 0;
                background-color: $white;

                @media (max-width: 345px) {
                    & #wrap-button-cancel-primary-menu {
                        width: 19rem;
                        justify-self: center;
                    }
                }
                @media (min-width: 367px) {
                    & #wrap-button-cancel-primary-menu {
                        width: 22rem;
                        justify-self: center;
                    }
                }
                @media (orientation: landscape) {
                    & #wrap-button-cancel-primary-menu {
                        width: 35rem;
                        justify-self: center;
                    }
                }
            }
        }
    }
}

