#main-title-area {
    background-image: url("../images/Betis-logo-watermark.png");
    background-repeat: no-repeat;
    background-size: 36.25rem 25.4375rem;
    background-position: center;
    background-attachment: fixed;
    border-top: 0.125rem solid $navy-blue;
    border-bottom: 0.125rem solid $navy-blue;
}

.main-title-complement {
    justify-self: center;
    width: 80%;
}

.box-title-3 {
    border: 0.125rem solid $black;
    width: 47%;
    @include margin-padding(0 0 0 10.625rem, 0.5rem 0.625rem);
}

.box-title-4 {
    border: 0.25rem solid $light-blue;
    position: relative;
    padding: 1.375rem 0.625rem;
    width: 18%;

    &::before {
        content: " ";
        position: absolute;
        border-left: 0.125rem solid $light-blue;
        right: 0.375rem;
        top: 0.375rem;
        left: -0.4375rem;
        bottom: 0.375rem;
    }
}

.box-important-information {
    border: 0.25rem solid $light-blue;
    position: relative;
    padding: 1.375rem 0.625rem;
    width: 30%;
    justify-self: center;

    &::before {
        content: " ";
        position: absolute;
        border-left: 0.125rem solid $light-blue;
        right: 0.375rem;
        top: 0.375rem;
        left: -0.4375rem;
        bottom: 0.375rem;
    }
}

@mixin box-title-3-smartphone-only-landscape {
    .box-title-3 {
        @media (min-width:659px) and (max-width:700px) {
            width: 80% !important;
        }
        @media (min-width:701px) and (max-width:730px) {
            width: 76% !important;
        }
        @media (min-width:731px) and (max-width:780px) {
            width: 73% !important;
        }
        @media (min-width:781px) and (max-width:820px) {
            width: 69% !important;
        }
        @media (min-width:821px) and (max-width:880px) {
            width: 66% !important;
        }
        @media (min-width:881px) and (max-width:929px) {
            width: 62% !important;
        }
        margin: 0 0 0 auto;
    }
}

@mixin main-title-area-smartphone-only-portrait {
    #main-title-area {
        @media (min-width: 320px) and (max-width: 360px) {
            background-size: 19.875rem 13.375rem;
        }

        @media (min-width: 360px) and (max-width: 380px) {
            background-size: 22.875rem 15.375rem;
        }
        
        @media (min-width: 380px) and (max-width: 400px) {
            background-size: 23.875rem 16.375rem;
        }
        
        @media (min-width: 400px) and (max-width: 420px) {
            background-size: 25.875rem 18.375rem;
        }

        @media (min-width: 420px) {
            background-size: 26.875rem 19.375rem;
        }
    }
}

@media only screen {
    // Smartphone (portrait and landscape) and some Tablets (from 768px)
    @media (min-width: 320px) and (max-width: 929px) {
        // Landscape only
        @media (orientation: landscape) {
            // Smartphone only
            @media (max-height: 480px) {
                #main-title-area {
                    background-size: 22.875rem 15.375rem;
                }

                @include box-title-3-smartphone-only-landscape();

                .box-title-4 {
                    width: 61% !important;
                }

                .presentation-little.box-title-4 {
                    width: 32% !important;
                }
    
                .box-important-information {
                    width: 68% !important;
                }
            }
        }
        // Portrait only
        @media (orientation: portrait) {
            @include main-title-area-smartphone-only-portrait();

            .box-title-3 {
                width: auto !important;
                margin: 0;
            }

            .box-title-4 {
                width: auto !important;
            }

            .presentation-little.box-title-4 {
                width: 60% !important;
            }

            .box-important-information {
                width: 85% !important;
            }
        }
    }

    // Some smartphone (until 926px), and Tablets (Portrait and Landscape)
    @media (min-width: 768px) and (max-width: 1280px) {
        // Not smartphone
        @media (min-height: 481px) {
            // Portrait only
            @media (orientation: portrait) {
                .box-title-3 {
                    margin: 0 0 0 12.625rem;
                    width: 57% !important;
                }

                .box-title-4 {
                    width: 52% !important;
                }

                .presentation-little.box-title-4 {
                    width: 30% !important;
                }

                .box-important-information {
                    width: 60% !important;
                }
            }
            // Landscape only
            @media (orientation: landscape) {
                .box-title-3 {
                    width: 55% !important;
                }

                .box-title-4 {
                    width: 42% !important;
                }

                .presentation-little.box-title-4 {
                    width: 25% !important;
                }

                .box-important-information {
                    width: 64% !important;
                }
            }
        }
    }
}