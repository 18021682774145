/************
 *   Grid   *
 ************/

@mixin grid-columns($display, $template-columns) {
    display: $display;
    grid-template-columns: $template-columns;
}

@mixin grid-columns-gap($display, $template-columns, $gap) {
    @include grid-columns($display, $template-columns);
    gap: $gap;
}

@mixin grid-columns-rows($display, $template-columns, $template-rows) {
    @include grid-columns($display, $template-columns);
    grid-template-rows: $template-rows;
}

@mixin grid-rows-gap($display, $template-rows, $gap) {
    display: $display;
    grid-template-rows: $template-rows;
    gap: $gap;
}

@mixin grid-columns-rows-gap($display, $template-columns, $template-rows, $gap) {
    @include grid-columns-rows($display, $template-columns, $template-rows);
    gap: $gap;
}

@mixin grid-columns-rows-column-gap-row-gap($display, $template-columns, $template-rows, $column-gap, $row-gap) {
    @include grid-columns-rows($display, $template-columns, $template-rows);
    column-gap: $column-gap;
    row-gap: $row-gap;
}

@mixin template-rows-gap($template-rows, $gap) {
    grid-template-rows: $template-rows;
    gap: $gap;
}

@mixin grid-columns-autorows($display, $template-columns, $auto-rows) {
    @include grid-columns($display, $template-columns);
    grid-auto-rows: $auto-rows;
}

@mixin grid-columns-autorows-gap($display, $template-columns, $auto-rows, $gap) {
    @include grid-columns-autorows($display, $template-columns, $auto-rows);
    gap: $gap;
}

/******************
 *   Typography   *
 ******************/

@mixin font-size-color($font-size, $color) {
    font-size: $font-size;
    color: $color;
}

@mixin font-weight-color($font-weight, $color) {
    font-weight: $font-weight;
    color: $color;
}

/****************************
 *   Organizational chart   *
 ****************************/

 @mixin organizational-chart-employee($float, $color) {
    float: $float;
    background-color: $sky-blue;
    color: $color;
    width: 6.875rem;
}

/**************
 *   Remain   *
 **************/

@mixin height-width($height, $width) {
    height: $height;
    width: $width;
}

@mixin margin-padding($margin, $padding) {
    margin: $margin;
    padding: $padding;
}