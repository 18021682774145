#footer {
    height: 95vh;
    width: 98.5vw;
    justify-self: center;
    margin: 0 0 0.15rem 0;
}

#footer-content {
    border: 0.1875rem solid $navy-blue;
    border-radius: 0.5rem;
}

#footer-logo-betis {
    background-image: url("../images/Betis-logo-footer.png");
    background-repeat: no-repeat;
    background-size: 25rem 17.5625rem;
}

#footer-betis-social-networks {
    justify-self: right;
}

@media only screen {

    // Smartphone (portrait and landscape) and some Tablets (from 768px Portrait and Landscape)
    @media (min-width: 320px) and (max-width: 1280px) {

        #footer {
            margin: 0 0 0.5rem 0;
        }

        #footer-content {
            padding: 0 0.5rem;
        }

        // for portrait only
        @media (orientation: portrait) {
            #footer {
                width: 97vw;
            }

            #footer-logo-betis {
                height: 32vh;
                background-image: url("../images/Betis-logo-footer-smartphone.png");
                background-size: 18.75rem 13.1875rem;
                background-position: center;
            }

            #footer-company {
                height: 22vh;
            }

            #footer-betis-social-networks {
                justify-self: left;
            }

            @media (min-height: 930px) {
                #footer {
                    width: 95vw;
                    padding: 0 1rem;
                }
            }

            @media (max-height: 759px) {

                #footer-logo-betis {
                    background-image: url("../images/Betis-logo-footer-little-smartphone.png");
                    background-size: 12.5rem 8.8125rem;
                }
            }
        }

        @media (orientation: landscape) {

            #footer {
                height: 80vh;
                width: 98vw;
            }

            @media (max-height: 480px) {

                #footer-logo-betis {
                    height: 10rem;
                    background-image: url("../images/Betis-logo-footer-little-smartphone.png");
                    background-size: 12.5rem 8.8125rem;
                }

                #footer-content {
                    height: 62vh;
                }

                #footer {
                    margin: 0 0 3rem 0;
                }
            }
        }

    }
}