#design-studies-network-extension {
    
    & ol {
        margin-top: 0;
        margin-bottom: 0;

        &[type=A] > li {
            margin: 1rem  0 0 0;

            &::marker {
                font-weight: bold;
            }
        }
    }

    & ul {
        list-style-type: disc;
    }
}

// From 1425px nav bar is weel displayed, so need to configure nav bar display only under 1425px
@media only screen and (max-width: 1425px) {

    .navigation {
        &.menu-displayed {
            & #primary-navigation {
                & ul {
                    background-color: $navy-blue;
                    @include margin-padding(1rem, 0.375rem);
                    border-radius: 0.5rem;

                    & li {
                        display: inline-block;
                        @include margin-padding(0, 0.125rem 0.5rem);
                        height: 3.8rem;
                        background-color: $white;
                        border-radius: 0.5rem;
                    }
                }
            }
        }
    }
}