#organizational-chart-content {
    background-color: $extremely-light-blue;
    @include height-width(13.125rem, 55%);
    margin: auto;
}

#organizational-chart-manager {
    background-color: $blue;
    width: 8.75rem;
    margin: auto;
}

#organizational-chart-main {
    height: 8.125rem;
    margin: 0.9375rem 0 0 0;
}

.organizational-chart-links-between-manager-employees {
    @include height-width(3.125rem, 5rem);
    margin: auto;
}

.organizational-chart-employees {
    @include height-width(3.125rem, 15.625rem);
    margin: auto;
}

.organizational-chart-employee-1 {
    @include organizational-chart-employee(left, $white);
}

.organizational-chart-employee-2 {
    @include organizational-chart-employee(right, $navy-blue);
}


@media only screen {

    // Smartphone (portrait and landscape) and some Tablets (from 768px)
    @media (min-width: 320px) and (max-width: 929px) {

        // Portrait only
        @media (orientation: portrait) {
            #organizational-chart {
                width: 98%;
            }
            #organizational-chart-content {
                width: 100%;
            }
        }

        // Landscape only
        @media (orientation: landscape) {
            #organizational-chart {
                width: 90%;
            }
        }
    }

    // Some smartphone (until 926px), and Tablets (Portrait and Landscape)
    @media (min-width: 768px) and (max-width: 1280px) {
        // Not smartphone
        @media (min-height: 481px) {
            // Landscape only
            @media (orientation: landscape) {
                #organizational-chart {
                    width: 60%;
                }
            }
            // Portrait only
            @media (orientation: portrait) {
                #organizational-chart {
                    width: 50%;
                }
            }
        }
    }
}