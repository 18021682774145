// Colors
$grey:                           #ccc;
$very-light-grey:                rgba(224, 224, 224, 0.161);
$white:                          #fff;
$navy-blue:                      rgba(5, 2, 96, 0.96);
$dark-navy-blue:                 rgba(2, 1, 49, 0.96);
$slightly-dark-blue:             rgb(18, 85, 162);
$blue:                           rgb(0, 0, 255);
$slightly-light-blue:            rgb(66, 101, 238);
$light-blue:                     rgba(31, 142, 209, 0.96);
$medium-light-blue:              rgba(14, 150, 228, 0.72);
$very-light-blue:                rgba(84, 176, 225, 0.68);
$really-very-light-blue:         rgba(102, 184, 230, 0.61);
$extremely-light-blue:           rgba(153, 207, 238, 0.33);
$sky-blue:                       #3fd2e9;
$really-light-green:             #00e4191f;
$red:                            rgb(255, 0, 13);
$red-chocolate:                  rgb(193, 5, 5);
$black:                          #000;