@import "./_grid_wrapper_main_title_area_media_properties";

.simple-grid-one-column {
    @include grid-columns(grid, 1fr);
}

.simple-grid-one-column-gap-one {
    @include grid-columns-gap(grid, 1fr, 1rem);
}

#body-container  {
    @include grid-columns-rows-gap(grid, 1fr, repeat(2, minmax(7rem, auto)), 3rem);
}

#body-header {
    @include grid-columns(grid, repeat(12, 1fr));
}

#logo-betis-header {
    grid-column: 3/4;
    grid-row: 1;
}

#navigation-bar {
    grid-column: 4 / 12;
    grid-row: 1;
}

.main {
    @include grid-columns-autorows(grid, 1fr, minmax(27rem, auto));
}

#wrapper-main-title-area {
    @include grid-columns-rows(grid, 1fr, 0 27rem 8rem);
    @include _grid_wrapper_main_title_area_media_properties;
}

#main-title-area {
    @include grid-columns-rows(grid, 1fr, 20% 60% 20%);
}

#who-we-are {
    @include grid-columns-rows-gap(grid, 1fr, 14rem auto auto 13rem, 1.5rem);
}

#homepage-technician-tasks-list {
    @include grid-columns-gap(grid, 1fr, 0.125rem);

    & li {
        display: grid;
        @include grid-columns(grid, 4% 96%);
    }
}

#interested-by-us {
    @include grid-columns-rows-gap(grid, 1fr, 8rem auto auto 0, 1.5rem);
}

#contact-us {
    @include grid-columns(grid, 1fr 1fr);
}

#contact-ways {
    @include grid-columns-rows-column-gap-row-gap(grid, 6% 72%, 0.5rem auto auto auto auto 9rem, 0.75rem, 1rem);
}

#form-sending-message {
    @include grid-columns-gap(grid, 90%, 0.0625rem);
}

#contact-details {
    @include grid-columns-autorows(grid, 1fr, auto 0.5rem auto auto auto);
}

#presentation-enterprise-specialisation {
    @include grid-columns-rows-gap(grid, 1fr, 12rem auto auto auto auto 10rem, 1.5rem);
}

#presentation-legal-info-expertise {
    @include grid-columns-rows-gap(grid, 1fr, 15rem auto auto auto auto auto auto 15rem, 1.5rem);
}

.presentation-legal-info {
    @include grid-columns-gap(grid, 2% 30%, 0.125rem);
}

#organizational-chart {
    @include grid-columns-rows-gap(grid, 1fr, 4rem auto auto auto auto auto 3rem, 1.5rem);
    justify-items: center;
}

#wrap-button-cancel-primary-menu, #wrapper-main-title-and-complement, .grid-page, 
#organizational-chart-complement, #role-design-office-enterprise-support-software {
    @extend .simple-grid-one-column;
}

#organizational-chart-complement {
    gap: 0.85rem;
}

#design-study-building-permit {
    @include grid-columns-rows-gap(grid, 1fr, 8rem auto auto auto auto auto 0.5rem, 1rem);
}

.title-1-with-bullet {
    @include grid-columns-gap(grid, 2% 95%, 1rem);
}

#building-plan-2D {
    @include grid-columns-rows-gap(grid, 1fr, 7rem auto auto auto 3rem, 1rem);
}

#building-plan-3D {
    @include grid-columns-rows-gap(grid, 1fr, 15rem auto auto auto 15rem, 2rem);
}

#electricity-network-connection {
    @include grid-columns-rows-gap(grid, 1fr, 5rem auto auto auto auto auto auto 2rem, 2rem);
}

#company-choice {
    @include grid-columns-rows-gap(grid, 1fr, 5rem auto auto auto auto auto 3rem, 2rem);
}

#tenant-entry, #design-studies-network-extension, #work-plans, #work-plans-1-500, #edm-network-legend,
#public-lighting-execution-plan, #electricity-lot-execution-plan, #electricity-lot-execution-plan-2, 
#electricity-lot-execution-plan-3, #electricity-lot-execution-plan-4, #network-execution-plan,
#electricity-building-execution-studies-plan, #execution-direction-goals,
#site-supervision-execution-direction-goals-software, #site-supervision-example,
#site-supervision-example-2, #site-supervision-example-3, #footer {
    @extend .simple-grid-one-column-gap-one;
}

#tenant-entry {
    grid-template-rows: 15rem auto auto auto auto 15rem;
}

#design-studies-network-extension {
    @include template-rows-gap(5rem auto auto auto 4rem, 0.5rem);
}

#role-design-office-enterprise-support-software {
    @include template-rows-gap(8rem auto auto auto auto 4rem, 0.5rem);
}

#role-design-office-enterprise-support {
    @include grid-columns-gap(grid, 1% 99%, 0.9375rem);
}

#execution-studies-software {
    @include grid-columns-gap(grid, 2% 98%, 0.0625rem);
}

#public-lighting-execution-plan, #electricity-lot-execution-plan-2,
#work-plans {
    grid-template-rows: 4.2rem auto 3rem;
}

#electricity-lot-execution-plan, #work-plans-1-500 {
    grid-template-rows: 15rem auto 15rem;
}

#site-supervision-example {
    grid-template-rows: 10rem auto 8rem;
}

#site-supervision-example-2 {
    grid-template-rows: 12rem auto 6rem;
}

#site-supervision-example-3 {
    grid-template-rows: 17rem auto 3rem;
}

#electricity-lot-execution-plan-3, #electricity-lot-execution-plan-4 {
    grid-template-rows: 8rem auto 3rem;
}

#electricity-building-execution-studies-plan {
    @include template-rows-gap(15rem auto auto auto 15rem, 0);
}

#transformation-post-connection-box {
    @include grid-columns-gap(grid, 1fr, 0.75rem);
}

#site-supervision-execution-direction-goals-software {
    grid-template-rows: 8rem auto auto auto auto 3rem;
}

#site-supervision-software {
    @include grid-columns-rows-gap(grid, 2% 98%, 2rem auto auto 2rem, 0.0625rem);
}

#box-message {
    @include grid-rows-gap(grid, 4rem, 1rem);
    justify-items: center;
}

#footer {
    grid-template-rows: 5rem auto;
}

#footer-content {
    @include grid-columns-rows(grid, 2fr 1fr 1fr 0.5fr, 20% 60% 20%);
}

#footer-company, #footer-services {
    @include grid-columns-autorows-gap(grid, 1fr, max-content, 0.7rem);
}

@media only screen {

    // Smartphone (portrait and landscape) and some Tablets (from 768px)
    @media (min-width: 320px) and (max-width: 929px) {

        .presentation-legal-info {
            grid-template-columns: 5% 90%;
        }

        // Landscape only
        @media (orientation: landscape) {
            #body-container  {
                gap : 0;
            }

            .main {
                grid-template-rows: minmax(16rem, auto);
            }

            // Smartphone only
            @media (max-height: 480px) {
                #who-we-are {
                    grid-template-rows: auto auto auto 15rem;
                    gap: 0.5rem;
                }

                #presentation-enterprise-specialisation {
                    grid-template-rows: auto auto auto auto auto 2rem;
                    gap: 0.5rem;
                }
            }

            #footer-content {
                @include grid-columns-rows(grid, 1.5fr 1fr 1.5fr 0, none);
            }
        }

        // Portrait only
        @media (orientation: portrait) {
            #who-we-are {
                grid-template-rows: 8rem auto auto 13rem;
            }

            #contact-ways {
                grid-template-rows: 0.5rem auto auto auto auto 2rem;
            }

            #presentation-enterprise-specialisation {
                grid-template-rows: 10rem auto auto auto auto 10rem;
            }

            #organizational-chart {
                grid-template-rows: 5rem auto auto auto auto auto 5rem;
            }

            #design-study-building-permit, #building-plan-3D {
                gap: 2rem;
            }

            #building-plan-2D {
                gap: 3rem;
            }

            #network-execution-plan {
                grid-template-rows: 2rem auto 15rem;
            }

            #role-design-office-enterprise-support-software {
                gap: 1.5rem;
            }

            #site-supervision-example-3 {
                grid-template-rows: 17rem auto 15rem;
            }

            // Smartphone only (height of some smartphone goes until 926px)
            @media (max-height: 929px) {
                #contact-us {
                    grid-template-columns: 1fr;
                }

                #footer-content {
                    @include grid-columns-rows(grid, 1fr, none);
                }
            }
        }
    }

    // Tablet (Portrait and Landscape)
    @media (max-width: 1280px) {

        @media (min-width: 768px) {
            // Portrait only
            @media (orientation: portrait) {
                #who-we-are {
                    grid-template-rows: 7rem auto auto 13rem;
                }

                #presentation-enterprise-specialisation {
                    grid-template-rows: 16rem auto auto auto auto 10rem;
                }

                #organizational-chart {
                    grid-template-rows: 12rem auto auto auto auto auto 18rem;
                }
                
                #network-execution-plan {
                    grid-template-rows: 3rem auto 15rem;
                }
                
                #site-supervision-example-3 {
                    grid-template-rows: 17rem auto 18rem;
                }
            }
            // Landscape only
            @media (orientation: landscape) {
                #organizational-chart {
                    grid-template-rows: 12rem auto auto auto auto auto 6rem;
                }

                #network-execution-plan {
                    grid-template-rows: 3rem auto 5rem;
                }
            }
        }

        @media (orientation: portrait) {
            #footer-content {
                @include grid-columns-rows(grid, 1fr, none);
            }
        }
    }

    // From 1426px, the nav bar is well displayed -> configure the display under 1426px
    @media (max-width: 1425px) {
        .navigation {
            grid-column: 10 / -1 !important;
            justify-self: center;

            &.menu-displayed {
                & #primary-navigation {
                    @include grid-columns-autorows(grid, 1fr, max-content);

                    & ul {
                        @include grid-columns-gap(grid, 1fr, 0.30rem);

                        @media (orientation: landscape) {
                            @include grid-columns-gap(grid, 1fr 1fr, 0.30rem);
                        }
                    }
                }
            }
        }
    }
}