.fixed-block {
    width: 100%;
    position: fixed;
    background: $white;
    z-index: 9995;
    left: 0;
    right: 0;
}

.sticky-block {
    height: 2rem;
    position: sticky;
    background-color: $white;
    bottom: 0;
}

.sticky-page {
    position: sticky;
    top: 2rem;
}

.after-sticky-page {
    background-color: $white;
    z-index: 1;
}

.colour-gradient-progressively-opened-box {
    background-image: linear-gradient(0deg, $extremely-light-blue,$really-very-light-blue 50% 87%, $very-light-blue);
    background-attachment: fixed;
    background-size: 100% 44rem;
    background-repeat: no-repeat;
}

#execution-studies-software {
    margin: 1rem 0 0 0;
}

.grid-page-content {
    width: 75%;
    justify-self: center;
}

@mixin grid-page-content-smartphone-and-tablet {
    .grid-page-content {
        @media (min-width: 320px) and (max-width: 767px) {
            width: 98%;
        }
        @media (min-width: 767px) and (max-width: 920px) {
            width: 96%;
        }
        @media (min-width: 920px) and (max-width: 1020px) {
            width: 94%;
        }
        @media (min-width: 1020px) and (max-width: 1099px) {
            width: 92%;
        }
        @media (min-width: 1099px) and (max-width: 1280px) {
            width: 90%;
        }
    }
}

@mixin sticky-block-tablet-only-portrait {
    .sticky-block {
        height: 24rem;
        @media (min-height: 1088px) and (max-height: 1152px){
            height: 28rem;
        }
        @media (min-height: 1152px) and (max-height: 1182px){
            height: 30rem;
        }
        @media (min-height: 1182px) and (max-height: 1215px){
            height: 32rem;
        }
        @media (min-height: 1215px) and (max-height: 1243px){
            height: 34rem;
        }
        @media (min-height: 1244px){
            height: 37rem;
        }
    }
}

@media only screen {

    @include grid-page-content-smartphone-and-tablet();

    // Smartphone (portrait and landscape) and some Tablets (from 768px)
    @media (min-width: 320px) and (max-width: 929px) {

        // Portrait only
        @media (orientation: portrait) {
            // Not tablets
            @media (max-height: 929px) {
                .sticky-block {
                    height: 14rem;
                }
                .aboutus-portrait.sticky-page {
                    top: 0;
                }
                .design-sticky.sticky-page {
                    top: -22rem;

                    @media (max-width: 380px) {
                        top: -19rem;
                    }
                }
                .execution-sticky.sticky-page {
                    top: -36rem;
                }
                .supervision-sticky.sticky-page {
                    top: -15rem;
                }
                .supervision-sticky-image.sticky-page {
                    top: 7rem;
                }
            }
        }

        // Landscape only
        @media (orientation: landscape) {
            @media (max-height: 480px) {
                .aboutus-landscape.sticky-page {
                    top: -5rem;
                }
                .presentation-sticky.sticky-page {
                    top: -5rem;
                }
                .design-sticky.sticky-page, .execution-sticky.sticky-page {
                    top: -32rem;
                }
                .supervision-sticky.sticky-page {
                    top: -28rem;
                }
                .supervision-sticky-image.sticky-page {
                    top: -15rem;
                }
                .smartphone-landscape.sticky-block {
                    height: 4rem;
                }
            }
        }
    }

    // Some smartphone, Tablets, and some Laptos
    @media (min-width: 768px) and (max-width: 1280px) {

        // Portrait only
        @media (orientation: portrait) {
            @include sticky-block-tablet-only-portrait();
        }

        // No smartphone
        @media (min-height: 481px) {
            // Portrait only
            @media (orientation: portrait) {
                .supervision-sticky.sticky-page {
                    top: 5rem;
                }

                .aboutus-portrait.sticky-page {
                    top: 0;
                }
            }
            // Landscape only
            @media (orientation: landscape) {
                .sticky-block {
                    height: 9rem;
                }

                .aboutus-landscape.sticky-page {
                    top: 0;
                }
            }
        }
    }
}